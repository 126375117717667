import styles from "./Layout.module.css";
import Kust_icon from "../../../../assets/icon.svg";
import {
  CopyRegular,
  ChatHelpFilled,
  ImportantRegular,
} from "@fluentui/react-icons";
import { Dialog, Stack, TextField } from "@fluentui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import {
  fecthCloseTicket,
  fetchConversationLink,
  fetchHelpWeb,
} from "../../../../api/widget.api";
import help_icon from "../../../../assets/help_icon.svg";
import online_icon from "../../../../assets/online_icon.png";
import EventContext from "../../../../EventContext";
import React from "react";
import {
  SystemMessageProvider,
  useSystemMessage,
} from "../../../SystemMessage/SystemMessageContext";
import "../../../../index.css";
import { useHelp, HelpProvider } from "../../../HelpContext/helpContext";

interface LayoutProps {
  children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const abortFuncs = useRef([] as AbortController[]);
  const [isSharePanelOpen, setIsSharePanelOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const { setSystemMessage } = useSystemMessage();
  const {
    setHelpMode,
    isHelpDisabled,
    setHelpDisabled,
    isHelpShowed,
    setHelpShowed,
  } = useHelp();
  const [token, setToken] = useState("");

  const onLoadSession = () => {
    const helpMode = sessionStorage.getItem("helpMode");
    if (helpMode) {
      const helped: boolean = JSON.parse(helpMode).help;
      setHelpDisabled(helped);
      setHelpShowed(helped);
      const closeDisable: boolean = JSON.parse(helpMode).role != "support";
    }
  };
  const { closeTriggered } = useContext(EventContext);

  React.useEffect(() => {
    // if (closeTriggered) {
    setHelpDisabled(false);
    setHelpShowed(true);

    // }
  }, [closeTriggered]);

  const { triggerEvent, eventTriggered } = useContext(EventContext);
  React.useEffect(() => {
    if (eventTriggered) {
      setHelpDisabled(true);
      setHelpShowed(true);
      setHelpMode({ help: true, role: "user" });
      setSystemMessage("Your request for help has been sent to Admin.");
    }
  }, [eventTriggered]);

  const onClickHelp = async () => {
    const abortController = new AbortController();
    abortFuncs.current.unshift(abortController);
    try {
      const help = await fetchHelpWeb(
        abortController.signal,
        sessionStorage.getItem("accessToken") as string
      );
      setHelpMode({ help: true, role: "user" });
      onLoadSession();
      triggerEvent();
      setHelpDisabled(true);
      setToken(help.token);
      setSystemMessage("Your request for help has been sent to Admin.");
    } catch (e) {
      console.log(e);
    } finally {
      abortFuncs.current = abortFuncs.current.filter(
        (a) => a !== abortController
      );
    }
  };

  useEffect(() => {
    setHelpDisabled(false);
    setToken(sessionStorage.getItem("accessToken") as string);
    onLoadSession();
  }, []);

  return (
    <HelpProvider>
      <SystemMessageProvider>
        <div className={styles.layout}>
          <header className={styles.header} role="banner">
            <div className={styles.headerContainer}>
              Kust
            </div>
          </header>
          {children}
        </div>
      </SystemMessageProvider>
    </HelpProvider>
  );
};

export default Layout;
