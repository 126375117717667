import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface HelpContextType {
  helpMode: { help: boolean, role: string } | null;
  setHelpMode: (mode: { help: boolean, role: string }) => void;
  isHelpDisabled: boolean;
  setHelpDisabled: (disabled: boolean) => void;
  isHelpShowed: boolean;
  setHelpShowed: (showed: boolean) => void;
}

const HelpContext = createContext<HelpContextType | undefined>(undefined);

interface HelpProviderProps {
  children: ReactNode;
}

export const HelpProvider: React.FC<HelpProviderProps> = ({ children }) => {
  const [helpMode, setHelpMode] = useState<{ help: boolean, role: string } | null>(null);
  const [isHelpDisabled, setHelpDisabled] = useState<boolean>(false);
  const [isHelpShowed, setHelpShowed] = useState<boolean>(false);

  useEffect(() => {
    setHelpShowed(false);
    const storedHelpMode = sessionStorage.getItem('helpMode');
    if (storedHelpMode) {
      const parsedHelpMode = JSON.parse(storedHelpMode);
      setHelpMode(parsedHelpMode);
      setHelpDisabled(parsedHelpMode.help);
      setHelpShowed(parsedHelpMode.help);
    }
  }, []);

  const updateHelpMode = (mode: { help: boolean, role: string }) => {
    setHelpMode(mode);
    setHelpDisabled(mode.help);
    setHelpShowed(mode.help);
    sessionStorage.setItem('helpMode', JSON.stringify(mode));
  };

  return (
    <HelpContext.Provider value={{ helpMode, setHelpMode: updateHelpMode, isHelpDisabled, setHelpDisabled , isHelpShowed, setHelpShowed}}>
      {children}
    </HelpContext.Provider>
  );
};

export const useHelp = () => {
  const context = useContext(HelpContext);
  if (context === undefined) {
    throw new Error('useHelp must be used within a HelpProvider');
  }
  return context;
};
