import { createContext, useContext, useState, ReactNode } from 'react';
import { HelpProvider } from '../HelpContext/helpContext';

interface SystemMessageContextProps {
    systemMessage: string;
    setSystemMessage: (message: string) => void;
}

const SystemMessageContext = createContext<SystemMessageContextProps | undefined>(undefined);

export const useSystemMessage = () => {
    const context = useContext(SystemMessageContext);
    if (!context) {
        throw new Error('useSystemMessage must be used within a SystemMessageProvider');
    }
    return context;
};

export const SystemMessageProvider = ({ children }: { children: ReactNode }) => {
    const [systemMessage, setSystemMessage] = useState<string>('');

    return (
        <HelpProvider>
            <SystemMessageContext.Provider value={{ systemMessage, setSystemMessage }}>
                {children}
            </SystemMessageContext.Provider>
        </HelpProvider>
    );
};
