import sign from 'jwt-encode';
import { useCookies } from 'react-cookie';


let counter = 0
export const createSession = (web_id:string): string => {
    return sign(
        { 
            web_id,
            session_id: SESSION_ID, 
        },
        import.meta.env.VITE_SECRET_SESS,
        {
            alg:'HS256',
            expiresIn: '1h',
            typ: 'JWT'
        }
      );
}

export const SESSION_ID = "" +new Date().getTime() +  Math.floor(Math.random() * (1000000 - 0 + 1)) + 0

export function increaseMessageCounter(): number{
    return counter++;
}
export function getCurrentCounter(): number{
    return counter;
}


 