import { useCookies } from "react-cookie";
import { SessionError } from "../errors/session.exception";
import { createSession } from "../services/temp-session.service";
import { UserInfo, ConversationRequest, ConversationSaveRequest, FeedbackSaveRequest, WebHelpPostRequest } from "./models";

const HOST = import.meta.env.VITE_API_HOST;

function checkSessionError(response: { status: number }) {
    if (response.status === 401) {
        throw new SessionError(`HTTP error! Status: ${response.status}`, {});
    }
    throw new Error(`HTTP error! Status: ${response.status}`, {});
}

// export async function fetchTokenValidation(token: string, abortSignal: AbortSignal): Promise<any> {
//     const response = await fetch(`${HOST}/api/validation`, {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json",
//             "Authorization": `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//             token: token,
//         }),
//         signal: abortSignal,
//         credentials: 'include'
//     });
//     if (!response.ok)
//         checkSessionError(response);
//     const responseBody = await response.json();
//     return responseBody;
// }
export async function fetchConversationLink(abortSignal: AbortSignal, token: string): Promise<any> {
    const response = await fetch(`${HOST}/api/conversation/share`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        signal: abortSignal,
        credentials: 'include'
    });
    if (!response.ok)
        checkSessionError(response)
    const responseBody = await response.json();
    return responseBody;
}

export async function fecthCloseTicket(abortSignal: AbortSignal, token: string): Promise<any> {
    const response = await fetch(`${HOST}/api/close`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },

        signal: abortSignal,
        credentials: 'include'
    });
    if (!response.ok)
        checkSessionError(response)
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseBody = await response.json();
    return responseBody;
}

export async function fetchHelpWeb(abortSignal: AbortSignal, token: string): Promise<any> {
    const response = await fetch(`${HOST}/api/help`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        signal: abortSignal,
        credentials: 'include'
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseBody = await response.json();
    return responseBody;
}

export async function createTicketSession(options: WebHelpPostRequest, abortSignal: AbortSignal): Promise<any> {
    const session = createSession(options.web_id)
    const response = await fetch(`${HOST}/api/web-ticket`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            session: session
        }),
        signal: abortSignal,
        credentials: 'include'
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseBody = await response.json();
    return responseBody;
}

export async function getConversationMessages(abortSignal: AbortSignal, token: string): Promise<any> {
    const response = await fetch(`${HOST}/api/conversation`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        signal: abortSignal,
        credentials: 'include'
    });
    if (!response.ok)
        checkSessionError(response)
    const responseBody = await response.json();
    return responseBody;
}

export async function conversationApi(options: ConversationRequest, abortSignal: AbortSignal, token: string): Promise<Response> {
    const response = await fetch(`${HOST}/api/conversation`, { 
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,

        },
        body: JSON.stringify({
            messages: options.messages
        }),
        signal: abortSignal,
        credentials: 'include'
    });
    return response;
}

export async function fetchConversationApi(options: ConversationSaveRequest, abortSignal: AbortSignal, token: string): Promise<Response> {
    const response = await fetch(`${HOST}/api/fetch-conversation`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({
            message_id: options.message_id,
            message: options.message,
            session_id: options.session_id,
            role: options.role,
        }),

        signal: abortSignal,
        credentials: 'include'
    });
    if (!response.ok)
        checkSessionError(response)

    return response;
}

export async function fetchFeedbackApi(options: FeedbackSaveRequest, abortSignal: AbortSignal): Promise<Response> {
    const response = await fetch(`${HOST}/api/fetch-feedback`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            
        },
        body: JSON.stringify({
            message_id: options.message_id,
            feedback: options.feedback,
            session_id: options.session_id,
        }),
        signal: abortSignal,
        credentials: 'include'
    });
    return response;
}

export async function getUserInfo(): Promise<UserInfo[]> {
    const response = await fetch(`${HOST}/.auth/me`);
    if (!response.ok) {
        console.log("No identity provider found. Access to chat will be blocked.")
        return [];
    }

    const payload = await response.json();
    return payload;
}