import { useState } from "react";
import { Icon, Stack, TextField } from "@fluentui/react";
import Send_icon from "../../../../assets/send_message_icon.png";
import Send_icon_white from "../../../../assets/paper-plane.svg";
import Send_icon_active from "../../../../assets/send_message_icon_active.png";
import styles from "./QuestionInput.module.css";

interface Props {
  onSend: (question: string) => void;
  disabled: boolean;
  placeholder?: string;
  clearOnSend?: boolean;
}

export const QuestionInput = ({
  onSend,
  disabled,
  placeholder,
  clearOnSend,
}: Props) => {
  const [question, setQuestion] = useState<string>("");

  const sendQuestion = () => {
    if (disabled || !question.trim()) {
      return;
    }

    onSend(question);

    if (clearOnSend) {
      setQuestion("");
    }
  };

  const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
    if (ev.key === "Enter" && !ev.shiftKey) {
      ev.preventDefault();
      sendQuestion();
    }
  };

  const onQuestionChange = (
    _ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    setQuestion(newValue || "");
  };

  return (
    <Stack
      horizontal
      className={`${styles.questionInputContainer} ${
        disabled ? styles.disabled : ""
      }`}
    >
      <TextField
        className={styles.questionInputTextArea}
        placeholder={placeholder}
        resizable={false}
        borderless
        multiline
        value={question}
        onChange={onQuestionChange}
        onKeyDown={onEnterPress}
        disabled={disabled}
        styles={{
          field: {
            fontSize: 14,
            fontWeight: "400",
            "::placeholder": {
              fontWeight: "400",
            },
            "::-webkit-scrollbar-thumb": {
              backgroundColor: "#d0d0d0",
              borderRadius: "10px",
            },
          },
        }}
      />
      <div
        className={`${styles.questionInputSendButtonContainer} ${
          question ? styles.active : ""
        }`}
        role="button"
        tabIndex={0}
        aria-label="Ask question button"
        onClick={sendQuestion}
        onKeyDown={(e) =>
          e.key === "Enter" || e.key === " " ? sendQuestion() : null
        }
        style={{
          height: '30px',
          background: 'black',
          padding: '4px',
          borderRadius: '100%',
          width: '30px'
        }}
      >
        <img
          src={question ? Send_icon_active : Send_icon_white}
          className={styles.questionInputSendButton}
          style={{
            alignItems: 'center',
            width: '15px',
            margin: '1px'
          }}
        />

      </div>
    </Stack>
  );
};
