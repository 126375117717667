import React, { lazy, Suspense, useState } from 'react';
import { createRoot, Root } from 'react-dom/client';
import ChatTogglestyles from './chat-container-style.module.css';
import { SystemMessageProvider } from '../SystemMessage/SystemMessageContext';
import { HelpProvider } from '../HelpContext/helpContext';

const serverUrl = import.meta.env.VITE_API_HOST;

declare global {
  interface Window {
    ChatWidget: {
      mount: (selector: string, props?: any) => void;
      unmount: (selector: string) => void;
      mountToggleButton: (buttonSelector: string, chatSelector: string) => void;
    };
  }
}

const ChatWidget = lazy(() => import('./chatWidget'));

function createContainer(selector: string, className: string): HTMLElement {
  const container = document.createElement('div');
  container.className = `${selector.replace('.', '')} ${className}`;
  document.body.appendChild(container);
  return container;
}

let userSessionID: string | null | undefined;
let roots: Map<string, Root> = new Map();

function getUserSessionID(): void {
  const hashParams = new URLSearchParams(window.location.hash.split('?')[1]);
  if (hashParams) {
    userSessionID = hashParams.get('id');
    if (userSessionID) return;
  }

  document.addEventListener('DOMContentLoaded', () => {
    const scriptTag = document.querySelector('script[data-id]') as HTMLElement;
    // const url = scriptTag?.getAttribute('src');

    // // get id Url param from this URL
    // if (!url) {
    //   console.warn("Script with src attribute not found");
    //   return;
    // }
    // const urlParams = new URLSearchParams(url!);
    // const id = urlParams.values()?.next()?.value
    // if (id) {
    //   userSessionID = id;
    //   return;
    // }
    if (scriptTag) {
      userSessionID = scriptTag.getAttribute('data-id');
    } else {
      console.warn("Script with data-id attribute not found");
    }
  });
}

getUserSessionID();

window.ChatWidget = {
  mount: (selector: string, props: any = {}) => {
    let container = document.querySelector(selector);

    if (!container) {
      container = createContainer(selector, ChatTogglestyles.chatWidgetContainer);
    }

    if (!roots.has(selector)) {
      roots.set(selector, createRoot(container));
    }

    const root = roots.get(selector) as Root;
    root.render(
      <HelpProvider>
        <SystemMessageProvider>
          <Suspense fallback={<div>Loading...</div>}>
            <ChatWidget {...props} id={userSessionID} />
          </Suspense>
        </SystemMessageProvider>
      </HelpProvider>
    );
  },
  unmount: (selector: string) => {
    const root = roots.get(selector);
    if (root) {
      root.unmount();
      roots.delete(selector);
    } else {
      console.error(`Root not found for selector: ${selector}`);
    }
  },

  mountToggleButton: (buttonSelector: string, chatSelector: string) => {
    let buttonContainer = document.querySelector(buttonSelector) as HTMLElement;
    let chatContainer = document.querySelector(chatSelector) as HTMLElement;

    if (!buttonContainer) {
      buttonContainer = createContainer(buttonSelector, ChatTogglestyles.button);
    }

    if (!chatContainer) {
      chatContainer = createContainer(chatSelector, ChatTogglestyles.chatWidgetContainer);
    }

    const button = document.createElement('button');
    button.innerHTML = `<img src="${serverUrl}/widget/chat_icon_white.svg" alt="Toggle Chat" class="${ChatTogglestyles.chatToggleIcon}"/>`;
    button.className = ChatTogglestyles.chatToggleButton;

    button.onclick = () => {
      let isDisplayed = chatContainer.style.display !== 'none' && chatContainer.style.display !== '';
      chatContainer.style.display = isDisplayed ? 'none' : 'block';
      if (!isDisplayed) {
        window.ChatWidget.mount(chatSelector);
      }
      const icon = button.querySelector('img');
      if (icon) {
        if (!isDisplayed) {
          button.innerHTML = `<img src="${serverUrl}/widget/chat_icon_close.svg" alt="Toggle Chat" class="${ChatTogglestyles.chatToggleIcon}"/>`;
        } else {
          button.innerHTML = `<img src="${serverUrl}/widget/chat_icon_white.svg" alt="Toggle Chat" class="${ChatTogglestyles.chatToggleIcon}"/>`;
        }
      }
    };

    buttonContainer.appendChild(button);
  }
};

window.addEventListener('DOMContentLoaded', () => {
  if (window.ChatWidget) {
    window.ChatWidget.mountToggleButton('.button', '.chat-widget-container');
  } else {
    console.error('ChatWidget is not defined');
  }
});

export { };
