import { useState } from "react";
import { Stack, TextField } from "@fluentui/react";
import { SendRegular } from "@fluentui/react-icons";
import Send_icon from "../../../../assets/send_message_icon.png";
import Send_icon_active from "../../../../assets/send_message_icon_active.png"
import styles from "./QuestionInput.module.css";


interface Props {
    onSend: (question: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
}

export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend }: Props) => {
    const [question, setQuestion] = useState<string>("");

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        onSend(question);

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setQuestion(newValue || "");
    };

    const sendQuestionDisabled = disabled || !question.trim();

    return (
        <Stack horizontal className={styles.questionInputContainer}>
   <TextField
    className={styles.questionInputTextArea}
    placeholder={placeholder}
    multiline
    resizable={false}
    borderless
    value={question}
    onChange={onQuestionChange}
    onKeyDown={onEnterPress}
    styles={{
        root: { height: '100%', width: '100%' },
        fieldGroup: {
            height: '100%',
            width: '100%',
            minHeight: '0',
            borderRadius: '1rem',
            display: 'flex',
            alignItems: 'center',
             
        },
        field: {
            height: '100%',
            width: '100%',
            padding: '0rem 0.3rem 0.1rem 0.3rem',
            margin: '0rem 0.2rem, 0rem, 0.2rem',
            lineHeight: '1rem',
            display: 'flex',
            alignItems: 'center', 
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '0.85rem',
            '::placeholder': {
                color: '#5966f3', 
            },
        },
    }}
/>



        <div className={`${styles.questionInputSendButtonContainer} ${question ? styles.active : ''}`}
            role="button"
            tabIndex={0}
            aria-label="Ask question button"
            onClick={sendQuestion}
            onKeyDown={e => e.key === 'Enter' || e.key === ' ' ? sendQuestion() : null}
        >
            <img src={question ? Send_icon_active : Send_icon} className={styles.questionInputSendButton} />
        </div>
    </Stack>
    );
};
